/**
 * SwipeGesture
 * Felix Kiesewetter <info@felixkiesewetter.de>
 * v1.6.0
 * 2022-06-24
 */
class SwipeGesture
{

    /**
     * SwipeGesturce constructor
     *
     * @param {node|null} node
     * @param {object} options
     */
    constructor(node=null, options={})
    {
        this.node = node;
        this.setSettings(options);
        this.reset();
        this.init();
    }

    /**
     * Initialise touch and mouse events
     */
    init()
    {
        // Buttons and links should not fire if swipe is in progress
        this.node.querySelectorAll(this.settings.preventActions)?.forEach((a) => {
            a.addEventListener('click', (e) => {
                if (this.preventActions) {
                    this.settings.debug && console.warn('[SwipeGesture]', 'User action prevented while swiping');
                    e.preventDefault();
                }
            });
        });

        // Init gestures
        this.settings.mouse && this.node.addEventListener('mousedown', (e) => this.start(e), false);
        this.node.addEventListener('touchstart', (e) => this.start(e), { passive: false, capture : true });
        this.settings.mouse ? this.node.addEventListener('mousemove', (e) => this.move(e), false) : null;
        this.node.addEventListener('touchmove', (e) => this.move(e), { passive: false, capture : true });
        this.settings.mouse ? this.node.addEventListener('mouseup', (e) => this.release(e), false) : null;
        this.node.addEventListener('touchend', (e) => this.release(e), { passive: false, capture : true });

        // Certain window events should release swipe
        this.settings.mouse && window.addEventListener('mousedown', (e) => this.release(e));
        window.addEventListener('contextmenu', (e) => this.release(e));

        // Disable drag functionality for some elements
        if (this.settings.preventDrag) {
            this.node.querySelectorAll(this.settings.preventDrag)?.forEach((d) => {
                d.classList.add('-prevent-drag');
                d.setAttribute('draggable', false);
                d.addEventListener('dragstart', (e) => {
                    e.preventDefault();
                    return false;
                });
            });
        }

        // Disable select functionality for some elements
        if (this.settings.preventSelect) {
            this.node.querySelectorAll(this.settings.preventSelect)?.forEach((s) => {
                s.classList.add('-prevent-select');
                s.setAttribute('unselectable', 'on');
            });
        }
    }

    /**
     * Start a swipe
     *
     * @param {event} e
     * @returns {boolean}
     */
    start(e)
    {
        const event = e.originalEvent || e;
        event.stopPropagation();
        if (this.inProgress || ('undefined' !== typeof event.button && this.settings.mouseMainButtonOnly && 0 !== event.button)) {
            return false;
        }
        this.inProgress = true;
        this.preventActions = true;
        this.startX = this.getMousePosition(event).client.x || event.touches[0].pageX;
        this.startY = this.getMousePosition(event).client.y || event.touches[0].pageY;
        this.lastCurrentX = this.startX;
        this.lastCurrentY = this.startY;
        this.interval = setInterval(() => {
                this.duration += 10;
            }, 10
        );
        if (typeof this.settings.onStart === 'function') {
            this.settings.debug && console.log('[SwipeGesture]', 'start');
            this.settings.onStart();
        }
    }

    /**
     * Move/drag on the node
     *
     * @param {event} e
     */
    move(e)
    {
        const event = e.originalEvent || e;
        if (!this.inProgress) {
            return;
        }
        this.currentX = this.getMousePosition(event).client.x || event.touches[0].pageX;
        this.currentY = this.getMousePosition(event).client.y || event.touches[0].pageY;
        this.distanceX = Math.round(Math.abs(this.currentX - this.startX));
        this.distanceY = Math.round(Math.abs(this.currentY - this.startY));
        if ((SwipeGesture.AXIS_X === this.settings.limitToAxis && this.distanceX < this.distanceY) || (SwipeGesture.AXIS_Y === this.settings.limitToAxis && this.distanceY < this.distanceX)) {
            return;
        }
        e.preventDefault();
        if (this.currentX !== this.lastCurrentX) {
            this.direction = (Math.sign(this.currentX - this.lastCurrentX) || 1) * (this.settings.inverseX ? 1 : -1);
        }
        const initialDirection = (Math.sign(this.currentX - this.startX) || 1) * (this.settings.inverseX ? 1 : -1);
        const dragX = -1 * initialDirection * this.distanceX;
        if (typeof this.settings.onMove === 'function') {
            this.settings.debug && console.log('[SwipeGesture]', 'move');
            this.settings.onMove({
                distance: this.distanceX,
                duration: this.duration,
                direction: this.direction
            });
        }
        this.node.style.setProperty('--dragX', `${dragX}px`);
        this.node.classList.add('-dragging');
        this.lastCurrentX = this.currentX;
        this.lastCurrentY = this.currentY;
    }

    /**
     * Release swipe
     *
     * @param {event} e
     */
    release(e)
    {
        if (!this.inProgress) {
            return true;
        }
        this.settings.debug && console.log('[SwipeGesture]', 'release');

        // Detect if swipe is fired
        const speed = this.distanceX / this.duration;
        const fire = speed > this.settings.speedThreshold || this.distanceX >= this.node.offsetWidth * this.settings.distanceThreshold;

        // Stop preventing actions if fired or if threshold was exceeded
        if (fire || this.settings.preventActionsThreshold <= this.distanceX) {
            clearInterval(this.preventActionsTimeout);
            this.preventActionsTimeout = setTimeout(() => {
                this.preventActions = false;
                delete(this.preventActionsTimeout);
            }, this.settings.preventActionsTimeout);
        }
        else if ('number' !== typeof this.preventActionsTimeout) {
            this.preventActions = false;
        }

        // Callback
        if (typeof this.settings.onRelease === 'function') {
            this.settings.onRelease({
                distance: this.distanceX,
                direction: this.direction,
                fire: fire,
                speed: speed || 0
            });
        }

        // Reset
        this.reset();
    }

    /**
     * Reset
     */
    reset()
    {
        this.settings.debug && console.log('[SwipeGesture]', 'reset');
        this.node.style.setProperty('--dragX', '0px');
        this.node.classList.remove('-dragging');
        this.inProgress = false;
        this.startX = 0;
        this.currentX = 0;
        this.distanceX = 0;
        this.duration = 0;
        clearTimeout(this.timeout);
        clearInterval(this.interval);
        if (typeof this.settings.onReset === 'function') {
            this.settings.onReset();
        }
    }

    /**
     * Set settings
     *
     * @param options
     */
    setSettings(options)
    {
        const defaultSettings = {
            debug: false,
            mouse: true,
            mouseMainButtonOnly: true,
            speedThreshold: 1,
            distanceThreshold: .5,
            inverseX: false,
            limitToAxis: SwipeGesture.AXIS_X,
            preventSelect: 'p, h1, h2, h3, h4, h5, h6, span, em, i',
            preventDrag: 'img, a',
            preventActions: 'a, button, [type="submit"]',
            preventActionsThreshold: 25,
            preventActionsTimeout: 1000,
            onStart: () => {
                // do stuff
            },
            onMove: (params) => {
                // do stuff
            },
            onRelease: (params) => {
                // do stuff
            },
            onReset: () => {
                // do stuff
            },
        };
        this.settings = {...defaultSettings, ...options};
        this.settings.debug && console.log('[SwipeGesture]', this.settings);
    }

    /**
     * Get mouse position
     *
     * @param {event} event
     * @return {object}
     */
    getMousePosition(event)
    {
        let pageX = event.pageX;
        let pageY = event.pageY;
        if (pageX === undefined) {
            pageX = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            pageY = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        const rect = event.target.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;
        return {
            client: { x: event.clientX, y: event.clientY }, // relative to the viewport
            screen: { x: event.screenX, y: event.screenY }, // relative to the physical screen
            offset: { x: offsetX, y: offsetY },             // relative to the event target
            page:   { x: pageX, y: pageY }                  // relative to the html document
        }
    }

}

Object.defineProperty(SwipeGesture, 'AXIS_X', {
    value: 'x'
});

Object.defineProperty(SwipeGesture, 'AXIS_Y', {
    value: 'y'
});

export default SwipeGesture;
