export default class Video {
    constructor() {
        this.waitForJQuery();
    }

    waitForJQuery() {
        if (window.jQuery) {
            this.init();
        } else {
            setTimeout(() => {
                this.waitForJQuery();
            }, 100);
        }
    }

    init() {
        $('.js-openVideoInModal').click(function(e){
            e.preventDefault();
            var youtubeId = $(this).attr('data-youtube-id');
            var vimeoId = $(this).attr('data-vimeo-id');
            console.log('[debug]', 'open video in modal', youtubeId, vimeoId)

            var modal = $('#modal');
            modal.addClass('youtubeModal');
            //modal.find('.modal-body').html('<iframe src="'+youtubeLink+'?autoplay=1" frameborder="0" allowfullscreen></iframe>');
            var videoContainerId = 'videoContainerIframe';

            modal.find('.modal-dialog').addClass('modal-lg');
            modal.modal('show');

            if (typeof youtubeId != 'undefined') {

                modal.find('.modal-body').html( '<div id="'+videoContainerId+'" class="videoContainerIframe"></div>' );

                /*
                            var playerOptions = {
                                videoId: youtubeId,
                                playerVars: { // https://developers.google.com/youtube/player_parameters?playerVersion=HTML5&hl=it
                                    controls: 1,
                                    showinfo: 0,
                                    autoplay: 1,
                                    loop: 0, // 1
                                    modestbranding: 1,
                                    wmode: 'transparent',
                                    vq: 'hd1080',
                                    quality: 'hd1080',
                                    cc_load_policy: 1,
                                    rel: 0
                                },
                                events: {
                                    'onReady': function(e) {
                                        e.target.seekTo(0);
                                        e.target.playVideo();
                                    }
                                }
                            };
                            var player = new YT.Player(videoContainerId, playerOptions);
                */
                // EXAMPLE OF YOUTUBE
                // https://www.youtube.com/embed/VOjJo2R0uUk?controls=0&showinfo=0&autoplay=1&loop=1&start=2&modestbranding=1&wmode=transparent&vq=hd1080&quality=hd1080&end=18&enablejsapi=1
                var youtubeVideoOptions = [];
                youtubeVideoOptions.push({'index': 'controls', 'val': 1});
                youtubeVideoOptions.push({'index': 'showinfo', 'val': 0});
                youtubeVideoOptions.push({'index': 'autoplay', 'val': 1});
                youtubeVideoOptions.push({'index': 'loop', 'val': 0});
                youtubeVideoOptions.push({'index': 'modestbranding', 'val': 1});
                youtubeVideoOptions.push({'index': 'wmode', 'val': 'transparent'});
                youtubeVideoOptions.push({'index': 'vq', 'val': 'hd1080'});
                youtubeVideoOptions.push({'index': 'quality', 'val': 'hd1080'});
                youtubeVideoOptions.push({'index': 'playlist', 'val': youtubeId});

                var youtubeAppendUrl = '';
                for (var i = 0; i<youtubeVideoOptions.length; i++) {
                    youtubeAppendUrl += youtubeVideoOptions[i].index + '=' + youtubeVideoOptions[i].val;
                    if (i != (youtubeVideoOptions.length - 1)) {
                        youtubeAppendUrl += '&';
                    }
                }

                var youtubeUrl = 'https://www.youtube.com/embed/'+youtubeId+'?'+youtubeAppendUrl;

                //modal.find('.videoContainerIframe').append('<iframe style="margin-top:-60px;" frameborder="0" allowfullscreen="1" allow="autoplay; encrypted-media" src="'+youtubeUrl+'"></iframe>');

                var modalAppendHtml = '';
                // if (!UC_UI.getServices().filter(service => 'YouTube Video' === service.name).pop().consent.status) {
                // } else {
                // }

                modalAppendHtml += '<iframe frameborder="0" allowfullscreen="1" allow="autoplay; encrypted-media" src="'+youtubeUrl+'" data-cookieconsent="marketing"></iframe>';
                modal.find('.videoContainerIframe').append( modalAppendHtml );



            } else if (typeof vimeoId != 'undefined') {

                var vimeoIframeStyle = 'width:100%;';
                var vimeoIframe = '<iframe style="'+vimeoIframeStyle+'" src="https://player.vimeo.com/video/'+vimeoId+'?autoplay=1"'+
                    ' frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
                modal.find('.modal-body').html( '<div id="'+videoContainerId+'" class="videoContainerIframe">'+vimeoIframe+'</div>' );
            }
        });
    }
}
