export default class HeroVideo {
    constructor() {
        const wrapper = document.getElementById('hero-video');
        if (!wrapper) {
            return;
        }

        this.wrapper = wrapper;


        this.current = '';

        window.addEventListener('resize', () => {
            this.setVideo();
        });

        this.setVideo();
    }

    setVideo() {
        // if aspect ratio is > 4:3
        if (window.innerWidth / window.innerHeight > 4 / 3) {
            if (this.current !== 'desktop') {
                this.current = 'desktop';
                this.wrapper.innerHTML = `<video preload="metadata" autoplay playsinline muted loop>
                    <source src="/videos/hero--desktop.webm?version=3" type="video/webm">
                    <source src="/videos/hero--desktop.mp4?version=3" type="video/mp4">
                </video>`
            }
        } else {
            if (this.current !== 'mobile') {
                this.current = 'mobile';
                this.wrapper.innerHTML = `<video preload="metadata" autoplay playsinline muted loop>
                    <source src="/videos/hero--mobile.webm?version=3" type="video/webm">
                    <source src="/videos/hero--mobile.mp4?version=3" type="video/mp4">
                </video>`
            }
        }
    }
}
