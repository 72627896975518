/**
 * AjaxRequest
 * Felix Kiesewetter <info@felixkiesewetter.de>
 * v1.4.0
 * 2022-07-25
 */
class AjaxRequest
{

    /**
     * AjaxRequest constructor
     *
     * @param {object} options
     */
    constructor(options)
    {
        // Require uri
        if ('undefined' === typeof options.uri) {
            console.error('AjaxRequest requires a uri');
            return;
        }

        // Parse options
        const uri = options.uri || options.url;
        const absolute = options.absolute || false;
        const debug = options.debug || false;
        const parse = options.parse || true;
        const callback = 'function' === typeof options.callback ? options.callback : () => {
            console.log(response, status);
        };

        // Build or get debug container
        let debugContainer = document.getElementById('ajax-request-debugger');
        if (debug) {
            if (!debugContainer) {
                debugContainer = document.createElement('aside');
                debugContainer.id = 'ajax-request-debugger';
                debugContainer.style.cssText = `
                    position: fixed;
                    background-color: #222;
                    color: white;
                    bottom: 5px;
                    right: 5px;
                    max-height: 200px;
                    z-index: 100;
                    overflow: auto;
                    display: none;
                    padding: 15px;
                `;
                document.body.append(debugContainer);
            }
        }

        // Private properties
        let contentType = AjaxRequest.CONTENT_TYPE_DEFAULT;
        let awaitReadyStatus = null;
        let isReady = false;
        let status = null;
        let response = null;
        let method = AjaxRequest.METHOD_POST;
        let data = {};

        /**
         * Send
         */
        this.send = () =>
        {
            window.dispatchEvent(new CustomEvent('ajaxRequestSend'));
            const xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState === 4) {
                    isReady = true;
                    status = this.status;
                    if (200 === this.status) {
                        if (debug) {
                            console.log('[AjaxRequest]', 'send() > successful', this);
                            debugContainer.innerHTML = '';
                            debugContainer.style.display = 'none';
                        }
                    }
                    else {
                        if (debug) {
                            console.error('[AjaxRequest]', 'send() > failed', this);
                            debugContainer.innerHTML = this.responseText;
                            debugContainer.style.display = 'block';
                        }
                    }
                    response = AjaxRequest.CONTENT_TYPE_JSON === contentType && true === parse ? JSON.parse(this.responseText) : this.responseText;
                    if (callback) {
                        callback(response, status);
                    }
                    window.dispatchEvent(new CustomEvent('ajaxRequestComplete'));
                }
            };
            this.uriEncodedData = this.uriEncodeData();
            const requestUri = this.getRequestUri(uri, absolute, method, debug);
            xhttp.open(method, requestUri, true);
            xhttp.setRequestHeader("Content-type", contentType);
            if (AjaxRequest.METHOD_POST === method) {
                xhttp.send(this.uriEncodedData);
            }
            else {
                xhttp.send();
            }
            debug && console.log('[AjaxRequest]', 'send()', requestUri, method, contentType, this.uriEncodedData);
        };

        /**
         * Uri-encode data
         *
         * @returns {string}
         */
        this.uriEncodeData = () =>
        {
            if (!data || !Object.entries(data).length) {
                return '';
            }
            let uriEncodedDataPairs = [];
            for (const name in data) {
                let value = data[name];
                if ('object' === typeof value) {
                    value = JSON.stringify(value);
                }
                uriEncodedDataPairs.push(`${encodeURIComponent(name)}=${encodeURIComponent(value)}`);
            }
            return uriEncodedDataPairs.join('&');
        };

        /**
         * Getter for the response
         */
        this.getResponse = () =>
        {
            awaitReadyStatus = setInterval(() => {
                console.log(isReady);
                if (isReady) {
                    clearInterval(awaitReadyStatus);
                    return response;
                }
            }, 50);
        };

        /**
         * Get public request url
         *
         * @returns {string}
         */
        this.getPublicRequesturi = () =>
        {
            return `${this.getRequestUri(uri, true)}?${this.uriEncodedData}`;
        };

        /**
         * Setter for the data object
         *
         * @param {object} d
         * @return {AjaxRequest} this
         */
        this.setData = (d) =>
        {
            data = d;
            return this;
        }

        /**
         * Setter for the method
         *
         * @param {string} m
         * @return {AjaxRequest} this
         */
        this.setMethod = (m = AjaxRequest.METHOD_POST) =>
        {
            if (!(`METHOD_${m}` in AjaxRequest)) {
                console.warn('[AjaxRequest]', `${m} is not a valid method`);
                method = AjaxRequest.METHOD_POST;
                return this;
            }
            method = m;
            return this;
        }

        /**
         * Setter for the content type
         *
         * @param {string} c
         * @return {AjaxRequest} this
         */
        this.setContentType = (c = AjaxRequest.CONTENT_TYPE_DEFAULT) =>
        {
            let contentTypeIsAllowed = false;
            for (const property of Object.getOwnPropertyNames(AjaxRequest)) {
                if ('CONTENT_TYPE' === property.substring(0, 12)) {
                    if (c === AjaxRequest[property]) {
                        contentTypeIsAllowed = true;
                    }
                }
            }
            if (!contentTypeIsAllowed) {
                console.warn('[AjaxRequest]', `${c} is not a valid content type`);
                contentType = AjaxRequest.CONTENT_TYPE_DEFAULT;
                return this;
            }
            contentType = c;
            return this;
        }

        // Set private properties from options
        this.setMethod(options.method);
        this.setData(options.data || null);
        this.setContentType(options.contentType || AjaxRequest.CONTENT_TYPE_DEFAULT);
    }

    /**
     * Get absolute or relative request uri w/ GET params append if required
     *
     * @param {string} uri
     * @param {boolean} absolute
     * @param {string} method
     * @param {boolean} debug
     * @returns {string|null}
     */
    getRequestUri(uri, absolute, method, debug)
    {
        if (!new RegExp('http').test(uri)) {
            const absoluteBaseuri = `${location.protocol}//${location.host}/`;
            const relativeBaseuri = absoluteBaseuri.replace(window.location.origin, '');
            if (absolute && !new RegExp(absoluteBaseuri).test(uri)) {
                uri = `${absoluteBaseuri}${uri.replace(/^\/+/, '')}`;
            }
            if (!new RegExp(relativeBaseuri).test(uri)) {
                uri = `${relativeBaseuri}${uri.replace(/^\/+/, '')}`;
            }
        }
        if (AjaxRequest.METHOD_GET === method) {
            uri = `${uri}?${this.uriEncodedData}`;
        }
        debug && console.log('[AjaxRequest]', 'getRequestUri()', uri);
        return uri;
    }

}

// Export class
export default AjaxRequest;

// Define constants
Object.defineProperty(AjaxRequest, 'CONTENT_TYPE_DEFAULT', { value: 'application/x-www-form-uriencoded' });
Object.defineProperty(AjaxRequest, 'CONTENT_TYPE_JSON', { value: 'application/json' });
Object.defineProperty(AjaxRequest, 'CONTENT_TYPE_HTML', { value: 'text/html' });
Object.defineProperty(AjaxRequest, 'CONTENT_TYPE_XML', { value: 'application/xml' });
Object.defineProperty(AjaxRequest, 'METHOD_GET', { value: 'GET' });
Object.defineProperty(AjaxRequest, 'METHOD_POST', { value: 'POST' });
