export default class PartnersGrid {
    constructor() {
        this.elements = document.querySelectorAll('.partners-grid');
        for (const element of this.elements) {
            this.init(element);
        }
    }

    init(element) {
        const partners = [...element.children];
        setInterval(() => {
            const hiddenPartners = partners.filter(partner => partner.getBoundingClientRect().width === 0);
            const visiblePartners = partners.filter(partner => partner.getBoundingClientRect().width > 0);
            const randomHiddenPartner = this.getRandomElement(hiddenPartners);
            const randomVisiblePartner = this.getRandomElement(visiblePartners);
            if (!randomVisiblePartner.animate) { // fallback if web animation api is not available
                randomHiddenPartner.parentElement.insertBefore(randomHiddenPartner, randomVisiblePartner);
                randomHiddenPartner.parentElement.appendChild(randomVisiblePartner);
            } else {
                const boundingBox = randomVisiblePartner.getBoundingClientRect();
                const animationTime = 1000;
                randomHiddenPartner.style.position = 'absolute';
                randomHiddenPartner.style.opacity = '0';
                randomHiddenPartner.style.display = 'block';
                randomHiddenPartner.style.left = `${this.getPositionRelativeToParent(randomVisiblePartner).left}px`;
                randomHiddenPartner.style.top = `${this.getPositionRelativeToParent(randomVisiblePartner).top}px`;
                randomHiddenPartner.style.width = `${boundingBox.width}px`;
                randomHiddenPartner.style.height = `${boundingBox.height}px`;
                randomHiddenPartner.animate([{opacity: 0}, {opacity: 1}], {
                    fill: 'forwards',
                    duration: animationTime,
                    easing: 'ease-out'
                });
                setTimeout(() => {
                    randomHiddenPartner.style = null;
                    randomHiddenPartner.parentElement.insertBefore(randomHiddenPartner, randomVisiblePartner);
                    randomHiddenPartner.parentElement.appendChild(randomVisiblePartner);
                }, animationTime)

                // setTimeout(() => {
                //     const  randomVisiblePartnerSrc = randomVisiblePartner.getAttribute('src');
                //     // randomVisiblePartner.style.opacity = '0';
                //     randomVisiblePartner.setAttribute('src', randomHiddenPartner.getAttribute('src'));
                //     // randomHiddenPartner.parentElement.insertBefore(randomHiddenPartner, randomVisiblePartner);
                //     // randomHiddenPartner.style.position = null;
                //     // randomHiddenPartner.style.opacity = null;
                //     // randomHiddenPartner.style.display = null;
                //     // randomHiddenPartner.style.left = null;
                //     // randomHiddenPartner.style.top = null;
                //     // randomHiddenPartner.style.width = null;
                //     // randomHiddenPartner.style.height = null;
                //     // randomHiddenPartner.parentElement.appendChild(randomVisiblePartner);
                //     // randomVisiblePartner.style.opacity = null;
                //     // randomVisiblePartner.setAttribute('src', randomVisiblePartnerSrc);
                //     setTimeout(() => {
                //         randomHiddenPartner.style = null;
                //     }, 100)
                // }, animationTime - 50);
            }
        }, 3000)
    }

    getRandomElement(elements) {
        return elements[Math.floor(Math.random() * elements.length)];
    }

    getPositionRelativeToParent(element) {
        const elementPosition = element.getBoundingClientRect();
        const parentPosition = element.parentNode.getBoundingClientRect();
        return {
            top: elementPosition.top - parentPosition.top,
            left: elementPosition.left - parentPosition.left,
        };
    }
}
