export default class MainNavigation {
    constructor() {
        this.mobileBreakPointMin = 1300;
        this.stickyThreshold = 200;
        this.scrollDirection = 'down';
        this.isSticky = false;
        this.setIsSticky(this.stickyThreshold < window.scrollY);

        this.initSticky();
        this.initSubMenu();
        this.initMobileToggle();
    }

    initSticky() {
        const threshold = 0;
        let lastScrollY = window.scrollY;
        let ticking = false;
        const scrollHandler = () => {
            const scrollY = window.scrollY;
            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            this.setScrollDirection(scrollY > lastScrollY || 0 === scrollY ? 'down' : 'up');
            lastScrollY = 0 < scrollY ? scrollY : 0;
            ticking = false;

            if (this.scrollDirection === 'down' && (scrollY < 50 || !this.isSticky)) {
                this.setIsSticky(this.stickyThreshold < scrollY);
            }
            if (this.scrollDirection === 'up' && this.isSticky) {
                this.setIsSticky(20 < scrollY);
            }
        };
        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(scrollHandler);
                ticking = true;
            }
        };
        window.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onScroll);
        window.addEventListener('resize', () => {
            this.forceClose();
        });
    }

    forceClose() {
        const menu = document.getElementById('main-menu');
        if (menu.querySelector('.-open')) {
            menu.querySelector('.-open').classList.remove('-open');
            document.body.classList.remove('main-menu-open')
        }
    }

    setIsSticky(isSticky) {
        // use sticky class only on desktop. It is fixed on mobile anyway
        if (window.innerWidth < this.mobileBreakPointMin) {
            isSticky = false;
        }
        this.isSticky = isSticky;
        document.getElementById('header').classList.toggle('-is-sticky', isSticky)
    }

    setScrollDirection(direction) {
        this.scrollDirection = direction;
    }

    initSubMenu() {
        const menu = document.getElementById('main-menu');
        for (const parent of menu.querySelectorAll('.has-children')) {
            parent.addEventListener('click', (e) => {
                e.preventDefault();

                // define link (<svg> might have been clicked as well)
                const link = 'A' !== e.target.tagName ? e.target.closest('a') : e.target;

                // delay before -open class will be applied (required for accordion-style animation)
                let delay = 0;

                // close on second click
                if (e.currentTarget.classList.contains('level-1') && e.currentTarget.classList.contains('-open')) {
                    e.currentTarget.classList.remove('-open');
                    document.body.classList.remove('main-menu-open');
                    return;
                }

                // close siblings when level 2 tree is opened
                // calculate current menu height for level 2 submenus (for accordion-style animation)
                if (e.currentTarget.classList.contains('level-2') && !e.currentTarget.classList.contains('-open')) {
                    delay = 50
                    document.querySelectorAll('#main-menu .level-2').forEach(level2Link => {
                        if (level2Link !== e.currentTarget) {
                            level2Link.classList.remove('-open')
                        }
                    })
                    const animatedMenu = e.currentTarget.parentNode.querySelector('ul')
                    animatedMenu.style.height = 'unset'
                    animatedMenu.style.position = 'absolute'
                    setTimeout(() => {
                        animatedMenu.style.setProperty('--expanded-height', `${Math.ceil(animatedMenu.getBoundingClientRect().height)}px`)
                        animatedMenu.style.height = '0'
                        setTimeout(() => {
                            animatedMenu.style.position = 'relative'
                        }, (delay / 2))
                    }, (delay / 2))
                }

                // close previously opened desktop submenu when clicking on first level item
                if (e.currentTarget.classList.contains('level-1') && menu.querySelector('.level-1.-open')) {
                    menu.querySelector('.level-1.-open').classList.remove('-open');
                }

                if (!delay) {
                    e.currentTarget.classList.toggle('-open')
                }
                else {
                    setTimeout(() => {
                        link.classList.toggle('-open')
                    }, delay)
                }
                const open = !!menu.querySelector('.-open');
                document.body.classList.toggle('main-menu-open', open);
                if (open && e.currentTarget.classList.contains('level-1')) {
                    // calculate correct position for sub menu
                    const left = e.currentTarget.getBoundingClientRect().x;
                    e.currentTarget.nextElementSibling.style.paddingLeft = `${left}px`;
                }

                // if menu is open add event listener to check if user clicked outside actual
                // menu to close it
                const bodyListener = (e) => {
                    if(e.target.tagName.toUpperCase() === 'BODY') {
                        document.body.removeEventListener('click', bodyListener);
                        this.forceClose();
                    }
                };
                if (e.currentTarget.classList.contains('-open')) {
                    document.body.addEventListener('click', bodyListener)
                } else {
                    document.body.removeEventListener('click', bodyListener)
                }
            })
        }
    }

    initMobileToggle() {
        const header = document.getElementById('header');
        if (!header) {
            return;
        }
        const toggle = header.querySelector('#header .mobile-toggle');
        if (!toggle) {
            return;
        }
        toggle.addEventListener('click', () => {
            header.classList.toggle('-open--mobile')
            toggle.classList.toggle('-open')
        })
    }
}
