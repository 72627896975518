import SwipeGesture from './SwipeGesture.js';

/**
 * Slider
 * Felix Kiesewetter <info@felixkiesewetter.de>
 * v1.7.0
 * 2022-03-09
 */
class Slider
{

    /**
     * Slider constructor
     *
     * @param {object} node
     * @param {object} options
     * @returns {void}
     */
    constructor(node, options)
    {
        this.node = node;
        if (!this.node) {
            this.settings.debug && console.error('[Slider]', `Slider expects first parameter to be a DOM element but variable node is of type ${typeof node}`);
            return;
        }
        this.node._sliderInstance = this;
        this.setSettings(options);
        this.init();
    }

    /**
     * Initialise slider
     *
     * @returns {void}
     */
    init()
    {
        this.storeOriginalMarkup();
        this.resize(0);
        this.initIntersectionObserver();
        window.addEventListener('resize', () => { this.resize(); });
    }

    /**
     * Initialise a global intersection observer for all sliders on a page
     */
    initIntersectionObserver()
    {
        if (!this.settings.intersectionObserver) return;

        const intersectionObserverHelper = document.createElement('aside');
        intersectionObserverHelper.classList.add('slider__intersection-observer-helper');
        this.node.appendChild(intersectionObserverHelper);

        this.isVisible = false;
        this.isOnlySlider = true;

        if ('undefined' === typeof window._sliderIntersectionObserver) {
            window._sliderInstances = [];
            const options = {
                rootMargin: '0px',
                threshold: 1
            }
            window._sliderIntersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach(entry => {

                    const _this = entry.target.parentNode._sliderInstance;
                    _this.isOnlySlider = 1 === window._sliderInstances.length;

                    // This slider just became invisible:
                    if (_this.isVisible && !entry.isIntersecting) {
                        _this.isVisible = false;
                        _this.settings.debug && console.log('[Slider]', 'IntersectionObserver', 'Slider just became invisible');
                        _this.stopAutoplay();
                        _this.stopVideos();
                        _this.node.dispatchEvent(new CustomEvent('stateChange', {
                            detail: {
                                visible: false
                            },
                        }));
                    }

                    // This slider just became invisible:
                    else if (!_this.isVisible && entry.isIntersecting) {
                        _this.isVisible = true;
                        _this.settings.debug && console.log('[Slider]', 'IntersectionObserver', 'Slider just became invisible');
                        _this.startAutoplay();
                        _this.resize();
                        _this.node.dispatchEvent(new CustomEvent('stateChange', {
                            detail: {
                                visible: true
                            },
                        }));
                    }

                    _this.settings.debug && console.log('[Slider]', 'IntersectionObserver', {
                        isOnlySlider: _this.isOnlySlider,
                        isVisible: _this.isVisible
                    });
                });
            }, options);
        }
        window._sliderInstances.push(this);
        this.isOnlySlider = window._sliderInstances.length <= 1;
        //window._sliderIntersectionObserver.observe(this.node); // TODO: maybe it would still be better to observe the whole element?
        window._sliderIntersectionObserver.observe(intersectionObserverHelper);
    }

    /**
     * Build the slider markup, prepare and/or reset some variables
     *
     * @returns {void}
     */
    build()
    {
        if (this.isActive) return;

        this.items = this.getItems();
        if (0 < this.items.length) {
            this.settings.debug && console.log('[Slider]', 'build()', 'begin ...');
            this.currentIndex = 0;
            this.currentTranslateX = 0;
            this.isActive = true;
            this.container = this.getContainer();
            this.viewport = this.getViewport();
            this.wrapper = this.getWrapper() || null;
            this.navigation = this.getNavigation();
            this.navigationItems = this.getNavigationItems();
            this.controls = this.getControls();
            this.autoplayIndicator = this.getAutoplayIndicator();
            this.implementKeyboardControls();
            this.initAutoplay();
            this.initGestures();
            this.settings.debug && console.log('[Slider]', 'build()', '... done');
        }
        else {
            this.settings.debug && console.log('[Slider]', 'build()', 'cancelled');
        }
    }

    /**
     * Destroy
     *
     * @returns {void}
     */
    destroy()
    {
        if (!this.isActive) return;

        this.isActive = false;
        this.node.innerHTML = this.originalMarkup;
        this.settings.debug && console.log('[Slider]', 'destroy()');
    }

    /**
     * Initialise autoplay
     *
     * @returns {void}
     */
    initAutoplay()
    {
        if (this.settings.slideContinously) {
            if (!this.settings.autoplay) {
                this.settings.autoplay = true;
                console.warn('Autoplay is disabled, but required if \'slideContinously\' is enabled');
            }
            this.settings.autoplayInterval = 10;
        }
        else if (!this.settings.autoplay) {
            return;
        }
        this.settings.debug && console.log('[Slider]', 'initAutoplay()');
        this.autoplayInterval = null;
        const autoPlayStopElements = this.node.querySelectorAll(this.settings.autoplayStopSelector) || this.container;
        const autoPlayStopEvents = this.settings.autoplayStopEvents.split(',');
        autoPlayStopEvents.forEach((eventType) => {
            eventType = eventType.trim();
            autoPlayStopElements.forEach((element) => {
                element.setAttribute('data-slider-hold', autoPlayStopEvents.join(',').replace(' ', ''));
                element.addEventListener(eventType, () => {
                    if (null !== this.autoplayInterval) {
                        this.settings.debug && console.log('[Slider]', 'hold autplay');
                        clearInterval(this.autoplayInterval);
                        this.autoplayInterval = null;
                        this.autoplayIndicator.classList.add(`${this.settings.autoplayIndicatorSelector.substring(1)}--hold`);
                    }
                });
            });
        });
        const autoPlayResumeEvents = this.settings.autoplayResumeEvents.split(',');
        autoPlayResumeEvents.forEach((eventType) => {
            eventType = eventType.trim();
            autoPlayStopElements.forEach((element) => {
                element.setAttribute('data-slider-resume', autoPlayResumeEvents.join(',').replace(' ', ''));
                element.addEventListener(eventType, () => {
                    if (null === this.autoplayInterval) {
                        this.settings.debug('[Slider]', 'resume autplay');
                        this.autoplayInterval = setInterval(() => {
                            this.runAutoplay();
                        }, this.settings.autoplayInterval);
                        this.autoplayIndicator.classList.remove(`${this.settings.autoplayIndicatorSelector.substring(1)}--hold`);
                    }
                });
            });
        });
    }

    /**
     * Start autoplay
     *
     * @returns {void}
     */
    startAutoplay()
    {
        if (!this.settings.autoplay || (this.settings.intersectionObserver && !this.isVisible)) return;

        // Dispatch autoplay event
        this.node.dispatchEvent(new CustomEvent('autoplay', {
            detail: {
                stopped: false,
                started: true,
                interval: this.settings.autoplayInterval
            },
        }));

        // Add data attribute
        this.node.setAttribute('data-slider-autoplay', 1);

        // Run autoplay interval
        this.autoplayInterval = setInterval(() => {
            this.runAutoplay();
        }, this.settings.autoplayInterval);

        // Debug
        this.settings.debug && console.log('[Slider]', 'startAutoplay()');
    }

    /**
     * Stop autoplay
     *
     * @returns {void}
     */
    stopAutoplay()
    {
        if (null === this.autoplayInterval) {
            return;
        }
        clearInterval(this.autoplayInterval);
        this.autoplayInterval = null;
        this.node.dispatchEvent(new CustomEvent('autoplay', {
            detail: {
                stopped: true,
                started: false,
                interval: this.settings.autoplayInterval
            },
        }));
        this.node.setAttribute('data-slider-autoplay', 0);
        this.settings.debug && console.log('[Slider]', 'stopAutoplay()');
    }

    /**
     * Initialise gestures
     *
     * @returns {void}
     */
    initGestures()
    {
        if (!this.settings.gestures || 1 >= this.items.length) {
            return;
        }
        this.container.classList.add('slider--implements-gestures');
        new SwipeGesture(this.wrapper, {
            debug: this.settings.debug,
            mouse: this.settings.gesturesWithMouse,
            distanceThreshold: (1 / this.settings.itemsPerView),
            onStart: () => {
                this.container.classList.add('slider--hold-transition');
            },
            onRelease: (params) => {
                this.container.classList.remove('slider--hold-transition');
                if (params.fire) {
                    0 < params.direction ? this.slideTo(this.getNextIndex()) : this.slideTo(this.getPrevIndex());
                }
            },
            onReset: () => {}
        });
    }

    /**
     * Run autoplay
     *
     * @returns {void}
     */
    runAutoplay()
    {
        if (this.settings.slideContinously) {
            this.slideBy(1);
        }
        else {
            this.settings.debug && console.log('[Slider]', 'runAutoplay()');
            this.slideTo(this.getNextIndex());
        }
    }

    /**
     * Store original markup
     *
     * @returns {void}
     */
    storeOriginalMarkup()
    {
        if (this.settings.breakpoint) {
            this.originalMarkup = this.node.innerHTML;
        }
    }

    /**
     * Get items
     *
     * @returns {object|null}
     */
    getItems()
    {
        const items = this.node.querySelectorAll(this.settings.itemSelector);
        if (!items.length) {
            this.settings.debug && console.error('[Slider]', `Slider needs at least one child with selector "${this.settings.itemSelector}"`);
            return;
        }
        else {
            for (const item of items) item.classList.add('slider__item');
        }
        this.node.style.setProperty('--itemsCount', items.length);
        return items;
    }

    /**
     * Get endless items
     *
     * @returns {array|null}
     */
    getEndlessItems()
    {
        if (Slider.SLIDE_MODE_ENDLESS !== this.settings.slideMode) return null;

        let endlessItems = [];
        this.items.forEach((item) => {
            const itemClone = item.cloneNode();
            itemClone.classList.add('slider__item--endless');
            itemClone.innerHTML = item.innerHTML;
            endlessItems.push(itemClone);
            this.wrapper.appendChild(itemClone);
        });
        this.debug && console.log('[Slider]', 'getEndlessItems()', endlessItems);
        return endlessItems;
    }

    /**
     * Get container
     *
     * @returns {object}
     */
    getContainer()
    {
        let container;
        if (this.settings.appendSliderToNode) {
            container = document.createElement('div');
            this.node.appendChild(container);
        }
        else {
            container = this.node;
        }
        container.classList.add('slider');
        container.classList.add(`slider--mode-${this.settings.mode}`);
        if (this.settings.debug) container.classList.add('slider--debug');
        return container;
    }

    /**
     * Get viewport
     *
     * Either gets viewport and wrapper by selector or builds it and returns element
     *
     * @returns {HTMLElement|null}
     */
    getViewport()
    {
        let viewport = this.node.querySelector(this.settings.viewportSelector);
        if (!viewport) {
            viewport = document.createElement('div');
            viewport.classList.add(this.settings.viewportSelector.substring(1));
            this.container.appendChild(viewport);
        }
        if (Slider.MODE_SLIDE !== this.settings.mode) {
            for (const item of this.items) {
                viewport.appendChild(item);
            }
            return null;
        }
        return viewport;
    }

    /**
     * Get wrapper
     *
     * Either gets wrapper by selector or builds it and returns element
     * Also appends items to wrapper
     * Appends items to container instead if mode is set to fade
     *
     * @returns {HTMLElement|null}
     */
    getWrapper()
    {
        let wrapper = this.node.querySelector(this.settings.wrapperSelector);
        if (!wrapper) {
            wrapper = document.createElement('div');
            wrapper.classList.add(this.settings.wrapperSelector.substring(1));
            for (const item of this.items) {
                wrapper.appendChild(item);
            }
            this.viewport.appendChild(wrapper);
        }
        if (!this.settings.slideContinously) {
            wrapper.style.transition = `transform ${this.settings.transitionDuration}ms ${this.settings.transitionEasing}`;
        }
        return wrapper;
    }

    /**
     * Get navigation
     *
     * If navigation is being used, either gets navigation by selector or builds it and returns element
     * If navigation is not being used, returns null
     * s
     * @returns {null|HTMLUListElement}
     */
    getNavigation()
    {
        if (true === this.settings.navigation) {
            let navigation = this.node.querySelector(this.settings.navigationSelector);
            if (!navigation) {
                navigation = document.createElement('ul');
                navigation.classList.add(this.settings.navigationSelector.substring(1));
                let index = 1;
                for (const item of this.items) {
                    let navigationItem = document.createElement('li');
                    navigationItem.innerHTML = `<span>${index}</span>`;
                    navigation.appendChild(navigationItem);
                    index ++;
                }
                this.container.appendChild(navigation);
            }
            return navigation;
        }
        return null;
    }

    /**
     * Get controls
     *
     * If controls are being used, either gets controls by selectors or builds them and returns object with prev and next controls
     * If controls are not being used, returns null
     *
     * @returns {null|object}
     */
    getControls()
    {
        if (true === this.settings.controls) {
            let prev = this.node.querySelector(`${this.settings.controlsSelector}--${this.settings.controlsPrevModifier}`);
            if (!prev && 1 < this.items.length) {
                prev = document.createElement('button');
                prev.textContent = '<';
                prev.classList.add(`${this.settings.controlsSelector.substring(1)}`);
                prev.classList.add(`${this.settings.controlsSelector.substring(1)}--${this.settings.controlsPrevModifier}`);
                this.container.appendChild(prev);
            }
            if (prev) {
                prev.addEventListener('click', () => {
                    this.slideTo(this.getPrevIndex());
                });
            }
            let next = this.node.querySelector(`${this.settings.controlsSelector}--${this.settings.controlsNextModifier}`);
            if (!next && 1 < this.items.length) {
                next = document.createElement('button');
                next.textContent = '>';
                next.classList.add(`${this.settings.controlsSelector.substring(1)}`);
                next.classList.add(`${this.settings.controlsSelector.substring(1)}--${this.settings.controlsNextModifier}`);
                this.container.appendChild(next);
            }
            if (next) {
                next.addEventListener('click', () => {
                    this.slideTo(this.getNextIndex());
                });
            }
            return {
                0: prev,
                1: next,
            };
        }
        return null;
    }

    /**
     * Get autoplay indicator
     *
     * If autplay is being used, either gets autoplay indicator by selector or builds it and returns element
     * If autoplay is not being used, returns null
     *
     * @returns {null|HTMLDivElement}
     */
    getAutoplayIndicator()
    {
        if (true === this.settings.autoplay && this.settings.autoplayIndicator) {
            let autoplayIndicator = this.node.querySelector(this.settings.autoplayIndicatorSelector);
            if (!autoplayIndicator) {
                autoplayIndicator = document.createElement('div');
                autoplayIndicator.classList.add(this.settings.autoplayIndicatorSelector.substring(1));
                autoplayIndicator.style.setProperty('--d', `${this.settings.autoplayInterval}ms`);
                this.container.appendChild(autoplayIndicator);
            }
            return autoplayIndicator;
        }
        return null;
    }

    /**
     * Get previous index
     *
     * @returns {int}
     */
    getPrevIndex()
    {
        let prevIndex;
        if (this.directionLock.previous) {
            prevIndex = parseInt(this.currentIndex);
        }
        const target = parseInt(this.currentIndex) - (this.settings.slideItemByItem ? 1 : parseInt(this.itemsPerView) || 1);
        const limiter = this.settings.slideItemByItem ? (parseInt(this.itemsPerView) || 1) : 0;
        switch (this.settings.slideMode) {
            case 'strict':
                prevIndex = Math.max(0, Math.min(this.items.length - limiter, target));
                break;
            case 'rotate':
                prevIndex = 0 <= target ? target : this.items.length - Math.max(1, limiter);
                break;
            case 'endless':
                prevIndex = target;
                break;
        }
        this.settings.debug && console.log('[Slider]', 'getPrevIndex()', prevIndex);
        return prevIndex;
    }

    /**
     * Get next index
     *
     * @returns {int}
     */
    getNextIndex()
    {
        let nextIndex;
        if (this.directionLock.next) {
            nextIndex = parseInt(this.currentIndex);
        }
        const target = parseInt(this.currentIndex) + (this.settings.slideItemByItem ? 1 : parseInt(this.itemsPerView) || 1);
        const limiter = this.settings.slideItemByItem ? (parseInt(this.itemsPerView) || 1) : 1;
        switch (this.settings.slideMode) {
            case 'strict':
                nextIndex = Math.max(0, Math.min(this.items.length - limiter, target));
                break;
            case 'rotate':
                nextIndex = target > this.items.length - limiter ? 0 : target;
                break;
            case 'endless':
                nextIndex = target;
                break;
        }
        this.settings.debug && console.log('[Slider]', 'getNextIndex()', nextIndex);
        return nextIndex;
    }

    /**
     * Get navigation items
     *
     * @returns {null|object}
     */
    getNavigationItems()
    {
        if (null === this.navigation) return null;

        const navigationItems = this.navigation.querySelectorAll('li');
        Object.keys(navigationItems).forEach((index) => {
            navigationItems[index].classList.add('slider__navigation__item');
            navigationItems[index].addEventListener('click', () => {
                const currentIteration = Math.floor(this.currentIndex / this.items.length);
                const slideTo = (currentIteration * this.items.length) + parseInt(index);
                this.slideTo(slideTo, true);
            });
        });
        return navigationItems;
    }

    /**
     * Get settings from data attributes
     *
     * @returns {object}
     */
    getSettingsFromDataAttributes()
    {
        let settingsFromDataAttributes = {};
        if (this.node.getAttribute('data-items-per-view')) {
            settingsFromDataAttributes.itemsPerView = parseInt(this.node.getAttribute('data-items-per-view'));
        }
        if (this.node.getAttribute('data-navigation')) {
            settingsFromDataAttributes.navigation = '1' === this.node.getAttribute('data-navigation') ? true : false;
        }
        if (this.node.getAttribute('data-controls')) {
            settingsFromDataAttributes.controls = '1' === this.node.getAttribute('data-controls') ? true : false;
        }
        return settingsFromDataAttributes;
    }

    /**
     * Get items per view
     *
     * @returns {int}
     */
    getItemsPerView()
    {
        let itemsPerView;

        // Compatibility fallback
        if (Slider.ITEMS_PER_VIEW_AUTO === this.settings.itemsPerView) {
            this.settings.itemsPerView = Math.min(5, this.items.length);
            this.settings.slideItemByItem = true;
            itemsPerView = parseInt(this.settings.itemsPerView);
        }

        // No breakpoints
        if (!this.settings.itemsPerViewBreakpoints) {
            itemsPerView = parseInt(this.settings.itemsPerView);
        }

        // Get items per view per breakpoint
        else if ('object' === typeof this.settings.itemsPerViewBreakpoints) {
            for (const breakpoint in this.settings.itemsPerViewBreakpoints) {
                if (parseInt(breakpoint) <= window.innerWidth) {
                    itemsPerView = this.settings.itemsPerViewBreakpoints[breakpoint];
                }
            }
        }

        // Debug and return current items per view
        this.settings.debug && console.log('[Slider]', 'getItemsPerView()', itemsPerView);
        return itemsPerView;
    }

    /**
     * Set settings by merging default settings with options
     *
     * @param {object} options
     * @returns {void}
     */
    setSettings(options)
    {
        const defaultSettings = {
            // Debug output using console {bool|string}, use id of slider to only debug one specific slider
            debug: false,
            // Mode {'slide'|'fade'}
            mode: 'slide', // or 'fade'
            // Append slider to node or use the container itself {bool}
            appendSliderToNode: false,
            // Automatically set height of the viewport to match current item {bool}
            autoHeight: false,
            // Don't slide to an indexed position, but roll continously {bool}
            slideContinously: false,
            // Slide mode {'strict'|'rotate'|'endless'}
            slideMode: 'strict',
            // If false: slide to 4 if itemsPerView is 3 // if true: slide single steps {bool}
            slideItemByItem: false,
            // Use autplay {bool}
            autoplay: false,
            // Autoplay interval in ms {int}
            autoplayInterval: 2500,
            // Use an autoplay indicator {bool}
            autoplayIndicator: true,
            // The autoplay indicator's selector {string}
            autoplayIndicatorSelector: '.slider__autoplay-indicator',
            // Hold autoplay when interacting with element with this selector {?string}
            autoplayStopSelector: false,
            // Hold autoplay on these interactions/events {string}
            autoplayStopEvents: 'click, mouseenter',
            // Resume autoplay on these interactions/events {string}
            autoplayResumeEvents: 'mouseleave',
            // Sliding to an element containing videos will autoplay them {bool}
            playVideos: true,
            // Use this selector to identify the slider's items {string}
            itemSelector: '.slider__item',
            // How many items should be visible at once {int}
            itemsPerView: 1,
            // Use different itemsPerView values for specific breakpoints, overrides itemsPerView {object|null|false}
            itemsPerViewBreakpoints: null,
            //itemsPerViewBreakpoints: {
            //    1280: 5,
            //    1024: 4,
            //    768: 3,
            //    512: 2,
            //    0: 1
            //},
            // If itemsPerView > items.length -> use less itemsPerView (grow items) {bool}
            itemsPerViewGrow: false,
            // Use this class modifier for the current item {string}
            itemsCurrentModifier: 'current',
            // Use controls (previous/next buttons) {bool}
            controls: false,
            // Identify controls by this selector {string}
            controlsSelector: '.slider__control',
            // Class modifier for the previous button {string}
            controlsPrevModifier: 'previous',
            // Class modifier for the next button {string}
            controlsNextModifier: 'next',
            // Class modifier for disabled control button(s) {string}
            controlsDisabledModifier: 'disabled',
            // Use the navigation {bool}
            navigation: false,
            // Identify navigation by this selector {string}
            navigationSelector: '.slider__navigation',
            // Identify navigation items by this selector {string}
            navigationItemSelector: '.slider__navigation__item',
            // Class modifier for the current navigation item {string}
            navigationItemCurrentModifier: 'current',
            // Length of the slide/fade animation in ms {int}
            transitionDuration: 500,
            // Easing function of the slide/fade animation {string}
            transitionEasing: 'ease-in-out',
            // Identifier for the items wrapper {string}
            wrapperSelector: '.slider__items',
            // Identifier for the viewport {string}
            viewportSelector: '.slider__viewport',
            // Keyboard left/right can control slider {bool}
            keyboard: true,
            // Implement gestures {bool}
            gestures: true,
            // Also implement gestures when using a mouse {bool}
            gesturesWithMouse: true,
            // Do not use slider at all if window is smaller than this breakpoint {false|int}
            breakpoint: false,
            // Use the intersection observer {bool}
            intersectionObserver: true
        };
        if ('string' === typeof options.debug) {
            options.debug = this.node.id === options.debug.replace('#', '') ? true : false;
        }
        const dataSettings = this.getSettingsFromDataAttributes();
        this.settings = this.validateSettings({
            ...defaultSettings, ...dataSettings, ...options,
        });
        this.settings.debug && console.log('[Slider]', 'setSettings()', this.settings);
    }

    /**
     * Validate settings
     *
     * @param settings
     * @returns {object}
     */
    validateSettings(settings={})
    {
        if ('fade' === settings.mode) {
            if ('rotate' !== settings.slideMode) {
                settings.slideMode = 'rotate';
                console.warn('Fade mode does not support any slideMode settings other than "rotate"');
            }
            if (1 !== settings.itemsPerView) {
                settings.itemsPerView = 1;
                console.warn('Fade mode does not support any itemsPerView settings other than "1"');
            }
        }
        return settings;
    }

    /**
     * Slide to
     *
     * Calculates new translateX value and slides the wrapper by changing its style value
     *
     * @param {int} targetIndex
     * @param {boolean} resetAutoplayInterval
     * @returns {void}
     */
    slideTo(targetIndex=0, resetAutoplayInterval=false)
    {
        this.settings.debug && console.log('[Slider]', `slideTo(${targetIndex})`, 'begin ...');
        if (resetAutoplayInterval) {
            this.stopAutoplay();
        }
        this.prepareEndless(targetIndex);
        const targetItem = this.items[targetIndex];
        const currentItem = this.items[this.currentIndex];
        switch (this.settings.mode) {
            case 'slide':
                const targetTranslateX = targetIndex * 100 / this.items.length;
                if (window.CSS && CSS.supports('color', 'var(--some-var')) {
                    this.wrapper.style.setProperty('--slideX', `${-1 * targetTranslateX}%`);
                }
                else {
                    this.wrapper.style.transform = (`translateX(${-1 * targetTranslateX}%)`);
                }
                this.currentTranslateX = targetTranslateX || 0;
                break;
            case 'fade':
                if (targetIndex !== this.currentIndex) {
                    targetItem.style.opacity = 0;
                    targetItem.style.zIndex = 3;
                    setTimeout(() => {
                        targetItem.style.transitionDuration = `${this.settings.transitionDuration}ms`;
                        targetItem.style.opacity = 1;
                    }, 10);
                    setTimeout(() => {
                        targetItem.style.zIndex = 2;
                        targetItem.style.transitionDuration = '0ms';
                        currentItem.style.zIndex = 1;
                        this.settings.debug && console.log('[Slider]', `slideTo(${targetIndex})`, '... done');
                    }, this.settings.transitionDuration);
                }
                break;
        }
        if (this.settings.autoHeight) {
            this.settings.debug && console.log('[Slider]', `true === autoHeight`, `set viewport height to ${targetItem.scrollHeight}px`);
            this.viewport.style.maxHeight = `${targetItem.scrollHeight}px`;
        }
        if (currentItem !== targetItem) this.stopVideos(currentItem);
        this.playVideos(targetItem);
        this.updateDirectionLock(targetIndex);
        this.updateNavigation(targetIndex);
        this.updateControls();
        this.updateItems(targetIndex);
        this.currentIndex = targetIndex;
        if (Slider.MODE_SLIDE === this.settings.mode) {
            setTimeout(() => {
                this.settings.debug && console.log('[Slider]', `slideTo(${targetIndex})`, '... done');
            }, this.settings.transitionDuration);
        }
        if (resetAutoplayInterval) {
            this.startAutoplay();
        }
        this.node.dispatchEvent(new CustomEvent('slide', {
            detail: this,
        }));
    }

    /**
     * Play videos
     *
     * @param {HTMLElement} item
     */
    playVideos(item)
    {
        if (this.settings.playVideos && this.isVisible && item) {
            const video = item.querySelector('video');
            if (video) {
                this.settings.debug && console.log('[Slider]', 'playVideos()');
                video.muted = true;
                video.play();
            }
        }
    }

    /**
     * Stop videos
     *
     * @param {HTMLElement|null} item
     */
    stopVideos(item=null)
    {
        if (null !== item) {
            const video = item.querySelector('video');
            if (video) {
                this.settings.debug && console.log('[Slider]', 'stopVideos()', item);
                video.pause();
            }
        }
        else {
            try {
                for (const item of this.items) {
                    const video = item.querySelector('video');
                    if (video) {
                        this.settings.debug && console.log('[Slider]', 'stopVideos()');
                        video.pause();
                    }
                }
            }
            catch(e) {
                console.warn(e);
            }
        }
    }

    /**
     * Slide by
     *
     * Moves the slider by given amount of pixels
     *
     * @param {int} amount
     * @returns {void}
     */
    slideBy(amount)
    {
        this.settings.debug && console.log('[Slider]', `slideBy(${amount})`);
        let translateX = this.currentTranslateX + amount;
        this.wrapper.style.setProperty('--slideX', `-${translateX}px`);
        this.currentTranslateX = translateX;

        // TODO: This is absolutely quick-and-dirty ---> fix this at some point:
        if ('undefined' === typeof this.cloned) this.cloned = false;
        if (this.currentTranslateX >= this.container.offsetWidth && !this.cloned) {
            for (let i = 0; 2 >= i; i++) {
                for (const item of this.items) {
                    this.wrapper.appendChild(item.cloneNode(true));
                }
            }
            this.cloned = true;
        }
    }

    /**
     * Resize function decides whether to build, update or destroy the slider
     *
     * @param {int} delay
     * @returns {void}
     */
    resize(delay=500)
    {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            this.settings.debug && console.log('[Slider]', 'resize()', 'begin ...');
            if ((false !== this.settings.breakpoint && this.settings.breakpoint > window.innerWidth) || !this.settings.breakpoint) {
                this.build();
                this.updateDimensions();
                this.slideTo(this.currentIndex, true);
            }
            else {
                this.destroy();
            }
            this.settings.debug && console.log('[Slider]', 'resize()', '... done');
        }, delay);
    }

    /**
     * Update items
     *
     * Current item gets an additional class name
     *
     * @param {int} targetIndex
     * @returns {void}
     */
    updateItems(targetIndex=0)
    {
        const currentClassName = `${'.' === this.settings.itemSelector.substring(0, 1) ? this.settings.itemSelector.substring(1) : 'slider__item'}--${this.settings.itemsCurrentModifier}`;
        Object.keys(this.items).forEach((index) => {
            parseInt(index) === this.normalizeIndex(targetIndex) ? this.items[index].classList.add(currentClassName) : this.items[index].classList.remove(currentClassName);
        });
    }

    /**
     * Update direction lock
     *
     * Lock sliding to the next or previous item(s)
     *
     * @param {int} targetIndex
     * @returns {void}
     */
    updateDirectionLock(targetIndex=0)
    {
        if ('undefined' === typeof this.directionLock) {
            this.directionLock = {};
        }
        switch (this.settings.slideMode) {
            case 'strict':
                this.directionLock.previous = (0 === targetIndex);
                this.directionLock.next = targetIndex >= this.items.length - this.itemsPerView;
                break;
            default:
                this.directionLock.previous = this.directionLock.next = this.itemsPerView >= this.items.length;
                break;
        }
        this.settings.debug && console.log('[Slider]', 'updateDirectionLock()', this.directionLock);
    }

    /**
     * Update controls' class name and lock status
     *
     * @returns {void}
     */
    updateControls()
    {
        if (this.controls) {
            const disabledClassName = `${this.settings.controlsSelector.substring(1)}--${this.settings.controlsDisabledModifier}`;
            this.directionLock.previous ? this.controls[0].classList.add(disabledClassName) : this.controls[0].classList.remove(disabledClassName);
            this.directionLock.next ? this.controls[1].classList.add(disabledClassName) : this.controls[1].classList.remove(disabledClassName);
        }
    }

    /**
     * Update navigation
     *
     * @param {int} targetIndex
     * @returns {void}
     */
    updateNavigation(targetIndex=0)
    {
        if (null !== this.navigationItems) {
            if (this.itemsPerView >= this.items.length) {
                for (const navigationItem of this.navigationItems) navigationItem.style.display = 'none';
                return;
            }
            const targetSection = Math.floor(targetIndex / this.itemsPerView);
            Object.keys(this.navigationItems).forEach((index) => {
                const navigationItem = this.navigationItems[index];
                const isUsed = !this.settings.slideItemByItem ? 0 === index % this.itemsPerView : true;
                const indicatesSection = Math.floor(index / this.itemsPerView);
                const currentClassName = `${this.settings.navigationItemSelector.substring(1)}--${this.settings.navigationItemCurrentModifier}`;
                navigationItem.style.display = isUsed ? 'block' : 'none';
                this.normalizeIndex(targetIndex) === parseInt(index) || targetSection === indicatesSection ? navigationItem.classList.add(currentClassName) : navigationItem.classList.remove(currentClassName);
            });
        }
    }

    /**
     * Update dimensions of elements by first defining items per view
     *
     * @returns {void}
     */
    updateDimensions()
    {
        if (Slider.MODE_SLIDE !== this.settings.mode) return;

        this.itemsPerView = this.getItemsPerView();
        this.wrapper.style.setProperty('--itemsPerView', this.itemsPerView);
        this.settings.debug && console.log('[Slider]', 'updateDimensions()');
    }

    /**
     * Normalize index
     *
     * If a given index is -1 or -2 it should actually be 4 or 3 in relation to an items set with 5 items
     *
     * @param {int} index
     * @returns {int}
     */
    normalizeIndex(index)
    {
        return (0 > index ? (5 === this.items.length - Math.abs(index  % this.items.length) ? 0 : this.items.length - Math.abs(index  % this.items.length)) : index % this.items.length);
    }

    /**
     * Prepare endless
     *
     * @param {int} targetIndex
     * @returns {void}
     */
    prepareEndless(targetIndex)
    {
        if (Slider.SLIDE_MODE_ENDLESS !== this.settings.slideMode) return;

        let prependOrAppend;

        // Get current number of items (incl. appended copies)
        const appendedAndOriginalItemsCount = this.wrapper.querySelectorAll(`${this.settings.itemSelector}--appended`).length;

        // Get current number of prepended items
        const prependedItemsCount = this.wrapper.querySelectorAll(`${this.settings.itemSelector}--prepended`).length;

        // Append items if sliding to the right
        if (targetIndex + this.itemsPerView > appendedAndOriginalItemsCount) {
            prependOrAppend = 'append';
            this.items.forEach((item) => {
                const itemClone = item.cloneNode(true);
                itemClone.classList.add(`${this.settings.itemSelector.substring(1)}--appended`);
                this.wrapper.appendChild(itemClone);
            });
        }

        // Prepend items if sliding to the left
        else if (targetIndex < 0 && Math.abs(targetIndex - (this.itemsPerView - 1)) > prependedItemsCount) {
            prependOrAppend = 'prepend';
            const currentFirstItem = this.wrapper.querySelector(this.settings.itemSelector);
            this.items.forEach((item) => {
                const itemClone = item.cloneNode(true);
                itemClone.classList.add(`${this.settings.itemSelector.substring(1)}--prepended`);
                this.wrapper.insertBefore(itemClone, currentFirstItem);
            });
            this.wrapper.style.setProperty('--prependedItemsCount', prependedItemsCount + this.items.length);
        }

        // Debug
        this.settings.debug && console.log('[Slider]', 'prepareEndless()', prependOrAppend);
    }

    /**
     * Implement keyboard controls
     *
     * @returns {void}
     */
    implementKeyboardControls()
    {
        if (!this.settings.keyboard) return;
        window.addEventListener('keyup', (e) => {
            if (!this.isVisible) {
                return;
            }
            if (39 === e.keyCode || 40 === e.keyCode) {
                e.preventDefault();
                this.slideTo(this.getNextIndex());
            }
            else if (37 === e.keyCode || 38 === e.keyCode) {
                e.preventDefault();
                this.slideTo(this.getPrevIndex());
            }
        });
    }

}

Object.defineProperty(Slider, 'ITEMS_PER_VIEW_AUTO', {
    value: 'auto' // DEPRECATED, leave it for compatibility reasons only
});

Object.defineProperty(Slider, 'SLIDE_MODE_STRICT', {
    value: 'strict'
});

Object.defineProperty(Slider, 'SLIDE_MODE_ROTATE', {
    value: 'rotate'
});

Object.defineProperty(Slider, 'SLIDE_MODE_ENDLESS', {
    value: 'endless'
});

Object.defineProperty(Slider, 'MODE_SLIDE', {
    value: 'slide'
});

Object.defineProperty(Slider, 'MODE_FADE', {
    value: 'fade'
});

Object.defineProperty(Slider, 'MODE_DISSOLVE', {
    value: 'fade'
});

export default Slider;
