import AjaxRequest from './vendor/AjaxRequest';

class Search
{

    /**
     * Search constructor
     *
     * @param {object} options
     */
    constructor(options = {})
    {
        this.searchForm = document.querySelector('form[data-tokeninput-action]');
        if (!this.searchForm) {
            return;
        }
        this.settings = {...{
            minCharacters: 3, // minimum number of characters required to perform a search
            searchThrottlerTimeout: 1000, // time in ms for the throttler
            dismissStatusTextsTimeout: 3000 // time in ms after which a status message will be cleared
        }, ...options};
        this.init();
    }

    /**
     * Initialises the plugin
     */
    init()
    {
        // Get document language
        this.lang = document.documentElement.getAttribute('lang') || 'de';

        // The original form input
        this.searchFormInput = this.searchForm.querySelector('input');

        // Get the AJAX request uri and set up a request object
        this.requestUri = this.searchForm.dataset.tokeninputAction;


        this.ajaxRequest = new AjaxRequest({
            debug: false,
            uri: this.requestUri,
            method: AjaxRequest.METHOD_GET,
            contentType: AjaxRequest.CONTENT_TYPE_JSON,
            callback: (response, status) => {
                this.doneSearch(response, status);
            }
        });

        // Prepare a timeout object for throttling
        this.searchTimeout = null;

        // Select or create required elements
        this.buildTokenList();
        this.buildTokenDropdown();

        // Hide the original form
        this.searchForm.style.display = 'none';

        // Add event listeners
        this.addEventHandlers();

        // TODO: REMOVE (Only to debug the jQuery stuff)
        document.body.addEventListener('click', () => {
            console.log(document.querySelector('.token-input-dropdown').cloneNode(true));
        });
    }

    /**
     * Selects or creates the "token list"
     */
    buildTokenList()
    {
        if (document.querySelector('.token-input-list')) {
            this.tokenList = document.querySelector('.token-input-list');
            return;
        }
        this.tokenList = document.createElement('ul');
        this.tokenList.classList.add('token-input-list');
        this.tokenList.innerHTML = '<li class="token-input-input-token"></li>';
        this.tokenListInput = document.createElement('input');
        this.tokenListInput.classList.id = 'token-input';
        this.tokenListInput.placeholder = this.searchFormInput.placeholder;
        this.tokenListInput.style.outline = 'none';
        this.tokenListInput.autocomplete = 'off';
        this.tokenListInput.type = 'text';
        this.tokenListTester = document.createElement('tester');
        this.tokenListTester.style.cssText = 'position: absolute; top: -9999px; left: -9999px; width: auto; font-size: 14.4px; font-family: Verdana; font-weight: 400; letter-spacing: 0px; white-space: nowrap;';
        this.tokenList.querySelector('li').append(this.tokenListInput, this.tokenListTester);
        this.searchForm.parentNode.insertBefore(this.tokenList, this.searchForm);
    }

    /**
     * Selects or creates the "token dropdown"
     */
    buildTokenDropdown()
    {
        if (document.querySelector('.token-input-dropdown')) {
            this.tokenDropdown = document.querySelector('.token-input-dropdown');
            return;
        }
        this.tokenDropdown = document.createElement('div');
        this.tokenDropdown.classList.add('token-input-dropdown');
        this.tokenDropdown.style.display = 'none';
        document.body.appendChild(this.tokenDropdown);
    }

    /**
     * Show and position the "token dropdown"
     */
    showTokenDropdown()
    {
        const left = this.tokenList.getBoundingClientRect().left + window.scrollX;
        const top = this.tokenList.getBoundingClientRect().top + window.scrollY + this.tokenList.offsetHeight;
        this.tokenDropdown.style.cssText = `display: block; position: absolute; left: ${left}px; top: ${top}px; width: ${this.tokenList.offsetWidth}px`;
    }

    /**
     * Hide the "token dropdown" and remove positioning
     */
    hideTokenDropdown()
    {
        this.tokenDropdown.style.cssText = 'display: none;';
    }

    /**
     * Adds event listeners to search components
     */
    addEventHandlers()
    {
        this.tokenListInput.addEventListener('keyup', (e) => {
            if (this.settings.minCharacters > this.tokenListInput.value.length) {
                this.hideTokenDropdown();
                return;
            }
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.doSearch();
            }, this.settings.searchThrottlerTimeout);
        });
        window.addEventListener('click', () => {
            this.hideTokenDropdown();
        });
    }

    /**
     * Performing a search
     */
    doSearch()
    {
        const isLoadingText = document.createElement('p');
        isLoadingText.textContent = 'de' === this.lang ? 'Es wird geladen' : 'Loading';
        this.tokenDropdown.innerHTML = '';
        this.tokenDropdown.appendChild(isLoadingText);
        this.showTokenDropdown();
        this.ajaxRequest.setData({q: this.tokenListInput.value.toLowerCase()}).send();
    }

    /**
     * After having performed a search request
     *
     * @param {object} response
     * @param {int} status
     */
    doneSearch(response, status)
    {
        if (status !== 200) {
            this.tokenDropdown.innerHTML = '';
            const statusErrorText = document.createElement('p');
            statusErrorText.textContent = 'de' === this.lang ? 'Es ist ein Fehler aufgetreten' : 'An error has occured';
            statusErrorText.style.color = 'red';
            this.tokenDropdown.appendChild(statusErrorText);
            setTimeout(() => { this.hideTokenDropdown(); }, this.settings.dismissStatusTextsTimeout);
            return;
        }
        if (!response.length) {
            this.tokenDropdown.innerHTML = '';
            const statusNoResultsText = document.createElement('p');
            statusNoResultsText.textContent = 'de' === this.lang ? 'Keine Ergebnisse' : 'No results';
            this.tokenDropdown.appendChild(statusNoResultsText);
            setTimeout(() => { this.hideTokenDropdown(); }, this.settings.dismissStatusTextsTimeout);
        }
        else {
            this.tokenDropdown.innerHTML = '<ul></ul>';
            for (const i in response) {
                const item = response[i];
                const tokenDropdownItem = document.createElement('li');
                tokenDropdownItem.innerHTML = `<div class="clearfix"><div class="pull-right">${item.type_name}</div>${item.name}</div>`;
                tokenDropdownItem.addEventListener('click', () => {
                    this.hideTokenDropdown();
                    const itemUri = `${window.location.protocol}//${window.location.host}${item.link}`;
                    window.location.href = itemUri;
                });
                this.tokenDropdown.querySelector('ul').appendChild(tokenDropdownItem);
            }
        }
    }

}

export default Search;
