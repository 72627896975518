/**
 * TeamGallery
 *
 * Initialises a plugin that exchanges and fades in random team members
 */
class TeamGallery
{

    /**
     * TeamGallery constructor
     *
     * @param {HTMLElement} teamContainer
     */
    constructor(teamContainer)
    {
        this.container = teamContainer;
        this.elements = this.container.querySelectorAll('.team__item');
        if (!this.elements) {
            return;
        }

        this.init();
    }

    /**
     * Initialise the plugin
     */
    init() {
        // On window resize, get visible and invisible elements
        let resizeTimeout;
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                this.visibleElements = [];
                this.nonVisibleElements = [];
                this.elements.forEach((element, index) => {
                    const widthWithoutScrollbar = document.documentElement.clientWidth;
                    if (
                        (9 <= index && widthWithoutScrollbar < 768) ||  // mobile => 3 columns
                        (12 <= index && widthWithoutScrollbar >= 768 && widthWithoutScrollbar < 992) || // tablet => 3 columns
                        (11 <= index && widthWithoutScrollbar >= 992 && widthWithoutScrollbar < 1450) || // desktop => 4 columns
                        (14 <= index && widthWithoutScrollbar >= 1450) // big desktop => 5 columns
                    ) {
                        element.classList.add('team__item--hidden');
                        this.nonVisibleElements.push(element);
                    }
                    else {
                        element.classList.remove('team__item--hidden');
                        this.visibleElements.push(element);
                    }
                });
            }, 500);
        });
        window.dispatchEvent(new Event('resize')); // TODO: this triggers ALL THE TIME?!

        // Begin
        this.requestAnimationFrame();
    }

    /**
     * Request animation frame after a new timeout of specified delay
     */
    requestAnimationFrame()
    {
        setTimeout(() => {
            window.requestAnimationFrame(() => this.exchangeRandomElement());
        }, 2500);
    }

    /**
     * Exchange a random element
     */
    exchangeRandomElement()
    {
        let randomElementKey = this.getRandomElementKey();
        while (randomElementKey === this.randomElementKey) {
            randomElementKey = this.getRandomElementKey();
        }
        const randomElement = this.visibleElements[randomElementKey];
        const randomElementPosition = this.getPositionRelativeToParent(randomElement);
        const fadeInElement = this.nonVisibleElements.shift();

        fadeInElement.classList.add('team__item--animating');
        fadeInElement.style.setProperty('--left', `${randomElementPosition.left}px`);
        fadeInElement.style.setProperty('--top', `${randomElementPosition.top}px`);
        fadeInElement.classList.remove('team__item--hidden');
        this.container.insertBefore(fadeInElement, randomElement);
        setTimeout(() => {
            fadeInElement.style.opacity = 1;
            setTimeout(() => {
                fadeInElement.classList.remove('team__item--animating');
                randomElement.classList.add('team__item--hidden');
                this.container.appendChild(randomElement);
                this.nonVisibleElements.push(randomElement);
                this.visibleElements.push(fadeInElement);
                this.visibleElements.splice(randomElementKey, 1);
            }, 775);
        }, 25);

        this.randomElementKey = randomElementKey;
        this.requestAnimationFrame();
    }

    /**
     * Get a random element key from currently visible elements
     *
     * @returns {number}
     */
    getRandomElementKey()
    {
        return Math.floor(Math.random() * this.visibleElements.length);
    }

    /**
     * Get an element's position relative to its parent (container)
     *
     * @param {HTMLElement} element
     * @returns {{top: number, left: number}}
     */
    getPositionRelativeToParent(element)
    {
        const elementPosition = element.getBoundingClientRect();
        const parentPosition = element.parentNode.getBoundingClientRect();
        return {
            top: elementPosition.top - parentPosition.top,
            left: elementPosition.left - parentPosition.left,
        };
    }
}

/**
 * TeamGalleryController
 *
 * Checks if any team galleries are on the current page and if so, constructs a TeamGallery plugin per element
 */
export default class TeamGalleryController
{

    /**
     * TeamGalleryController constructor
     */
    constructor()
    {
        const teamContainers = document.querySelectorAll('.team__container');
        if (!teamContainers) {
            return;
        }

        teamContainers.forEach((teamContainer) => {
            new TeamGallery(teamContainer);
        });
    }
}
