/**
 * MagazineList
 *
 * Initialises a magazine list plugin
 */
class MagazineList
{

    /**
     * MagazineList constructor
     *
     * @param {HTMLElement} magazineContainer
     */
    constructor(magazineContainer)
    {
        this.container = magazineContainer;
        this.items = this.container.querySelectorAll('.magazine-item');
        if (!this.items) {
            return;
        }

        this.init();
    }

    /**
     * Initialise the plugin
     */
    init() {

        // Fake :hover on touch
        this.items.forEach((item) => {
            item.querySelector('.magazine-item__link').addEventListener('touchstart', (e) => {
                e.stopImmediatePropagation();
                if (item.classList.contains('-hover')) {
                    return true;
                }
                e.preventDefault();
                this.unHoverItems();
                item.classList.add('-hover');
            });
        });
        window.addEventListener('touchstart', () => {
            this.unHoverItems();
        });

        // Scroll to magazine if anchor exists
        const magazineAnchor = document.getElementById('magazine');
        if (magazineAnchor) {
            magazineAnchor.scrollIntoView({behavior: 'smooth'});
        }
    }

    /**
     * Remove :hover state from all items
     */
    unHoverItems()
    {
        this.items.forEach((item) => {
            item.classList.remove('-hover');
        });
    }
}

/**
 * MagazineDetails
 *
 * Initialises a magazine details plugin
 */
class MagazineDetails
{

    /**
     * MagazineDetails constructor
     *
     * @param {HTMLElement} magazineContainer
     */
    constructor(magazineContainer)
    {
        this.container = magazineContainer;
        this.items = this.container.querySelectorAll('.magazine-item');
        if (!this.items) {
            return;
        }

        this.init();
    }

    /**
     * Initialise the plugin
     */
    init() {

        // Initialise mobile table of contents
        const tableOfContentsMenu = this.container.querySelector('.magazine__table-of-contents ul');
        const tableOfContentsToggle = this.container.querySelector('.magazine__table-of-contents button');
        if (tableOfContentsMenu && tableOfContentsToggle) {
            tableOfContentsToggle.addEventListener('click', () => {
                tableOfContentsToggle.classList.toggle('-open');
                if (!tableOfContentsMenu.classList.contains('-expanded')) {
                    tableOfContentsMenu.style.visibility = 'hidden';
                    setTimeout(() => {
                        tableOfContentsMenu.style.height = 'auto';
                        tableOfContentsMenu.style.setProperty('--height', `${tableOfContentsMenu.offsetHeight}px`);
                        tableOfContentsMenu.style.height = 0;
                        tableOfContentsMenu.style.visibility = 'visible';
                        setTimeout(() => {
                            tableOfContentsMenu.classList.toggle('-expanded');
                        }, 10);
                    }, 10);
                }
                else {
                    tableOfContentsMenu.classList.remove('-expanded');
                }
            });
        }
    }
}

/**
 * MagazineController
 *
 * Checks if any magazine lists or the magazine details are on the current page
 * If so, constructs either a MagazineList or MagazineDetail plugin per element
 */
export default class MagazineController
{

    /**
     * MagazineController constructor
     */
    constructor()
    {
        const magazineContainers = document.querySelectorAll('.magazine');
        if (!magazineContainers) {
            return;
        }

        magazineContainers.forEach((magazineContainer) => {
            if (magazineContainer.classList.contains('magazine--list')) {
                new MagazineList(magazineContainer);
            }
            else if (magazineContainer.classList.contains('magazine--detail')) {
                new MagazineDetails(magazineContainer);
            }
        });
    }
}


