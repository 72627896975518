import './scss/main.scss';

import HeroVideo from './modules/HeroVideo';
import './modules/TeamGallery';
import MainNavigation from "./modules/MainNavigation";
import Slider from "./modules/vendor/Slider";
import TeamGalleryController from "./modules/TeamGallery";
import InstagramController from "./modules/Instagram";
import MagazineController from "./modules/Magazine";
import PartnersGrid from './modules/PartnersGrid';
import Video from './modules/Video';
import Search from './modules/Search';
import jQuery from "jquery";
import initMain from './main';
import "friendly-challenge/widget";

const startApp = () => {
    new HeroVideo();

    initMain(jQuery);

    // Init magazine lists and detail views
    new MagazineController();

    // Init team galleries
    new TeamGalleryController();

    // Init instagram tiles
    new InstagramController();

    // Init main navigation
    new MainNavigation();

    new PartnersGrid();

    new Video();

    // Init ajax search boxes
    new Search({
        minCharacters: 3
    });

    // Init logo carousel sliders
    const logoCarousels = document.querySelectorAll('.logocarousel__slider.slider');
    if (logoCarousels) {
        logoCarousels.forEach((logoCarousel) => {
            new Slider(logoCarousel, {
                slideMode: Slider.SLIDE_MODE_ENDLESS,
                slideItemByItem: true,
                controls: true,
                debug: false,
                gestures: true,
                intersectionObserver: false,
                autoplay: true,
                autoplayInterval: 5000,
                itemsPerViewBreakpoints: {
                    1280: 5,
                    1024: 4,
                    768: 3,
                    512: 2,
                    0: 1
                },
            });
        });
    }

    // Init reference sliders
    const references = document.querySelectorAll('.teaser-reference');
    if (references) {
        references.forEach((teaserArea) => {
            const slides = teaserArea.querySelectorAll('.teaser-reference__slide');
            if (slides && slides.length > 1) {
                new Slider(teaserArea, {
                    itemSelector: '.teaser-reference__slide',
                    slideMode: Slider.SLIDE_MODE_ENDLESS,
                    slideItemByItem: true,
                    controls: true,
                    navigation: true,
                    debug: false,
                    gestures: true,
                    gesturesWithMouse: false,
                    intersectionObserver: false,
                    autoplay: true,
                    autoplayInterval: 10000,
                    itemsPerViewBreakpoints: {
                        0: 1
                    },
                });
            }
        })
    }
};

if (document.readyState === "complete") {
    // Fully loaded!
    startApp();
} else if (document.readyState === "interactive") {
    // DOM ready! Images, frames, and other subresources are still downloading.
    startApp();
} else {
    // Loading still in progress.
    // To wait for it to complete, add "DOMContentLoaded" or "load" listeners.

    window.addEventListener("DOMContentLoaded", () => {
        // DOM ready! Images, frames, and other subresources are still downloading.
        startApp();
    });

    window.addEventListener("load", () => {
        // Fully loaded!
    });
}

