/**
 * Instagram module
 */
class Instagram
{

    #container;
    #tiles;

    /**
     * Instagram constructor
     */
    constructor(container)
    {
        this.#container = container;
        this.#tiles = this.#container.querySelectorAll('.instagram__tile');
        if (!this.#tiles) {
            return;
        }
        this.init();
    }

    /**
     * Initialise
     */
    init()
    {
        this.#tiles.forEach((tile) => {
            tile.addEventListener('touchend', (e) => {
                setTimeout(() => {
                    tile.classList.toggle('-hover');
                }, 25);
                this.#tiles.forEach((otherTile) => {
                    if (tile === otherTile) {
                        return;
                    }
                    otherTile.classList.remove('-hover');
                    if ('A' === e.target.tagName.toUpperCase()) {
                        e.target.click();
                    }
                });
            });
        });
    }
}

/**
 * Instagram Controller
 *
 * Looks up instagram areas/plugins and intitialises an Instagram module for each
 */
export default class InstagramController
{

    /**
     * InstagramController constructor
     */
    constructor()
    {
        const instagramAreas = document.querySelectorAll('.pimcore_area_instagramfeedobjs');
        if (!instagramAreas) {
            return;
        }
        instagramAreas.forEach((instagramArea) => {
            new Instagram(instagramArea);
        });
    }
}
